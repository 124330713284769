const LocationsMap = () => {
  return (
    <main>
      <section className="home-pages" style={{'min-height': '70vh'}}>
      <div className="intro pregnancy-intro">
          <h1>Related locations</h1>
        </div>
      </section>
      <section className="map-container">
          <iframe className="frame" title="locationsMap" src="https://www.google.com/maps/d/u/0/embed?mid=1saDe3_DF_Xof4vDaHRtO_uCasTc7_7w&ehbc=2E312F" frameBorder={0}></iframe>
      </section>
    </main>
  )  
}

export default LocationsMap;