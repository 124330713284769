// import React, { useState } from 'react';
import '../../App.css';
import './PregnancyCare.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import library from '../../LanguageLib';
import { useOutletContext } from 'react-router';

const PregnancyCare = () => {
  const [language] = useOutletContext();
  // const [iframeActive, setIframeActive] = useState(false);
  // const [ytUrl, setYtUrl] = useState("https://www.youtube.com/embed/KIOelF8DKC8?origin=http://birthingdiversity.ca")

  // const onIntroVidPlay = () => {
  //   setIframeActive(true);
  //   setYtUrl('https://www.youtube.com/embed/KIOelF8DKC8?autoplay=1');
  // }

  return (
    <main className='scroll-container'>
      <section className='home-pages'>
        <div className="intro pregnancy-intro">
          <h1>{library[language].pregnancyInfo.introHeader}</h1>
          <br/>
          <article>{library[language].pregnancyInfo.intro}</article>
        </div>
      </section>
      <section className="home-introduction">
        <div className="home-vid">
          <div className="vid-wrapper">
            {/* <iframe 
              id='intro-iframe'
              className={iframeActive ? "active" : "inactive"}
              src={ytUrl} 
              title="Nursing vs Midwifery: Which Career is Right For Me?"
              allowFullScreen
            ></iframe> */}
            <div className="cover-iframe-color"></div>
            <div className="cover-iframe-img"></div>
            <div className="cover-iframe-top-container" >
              <div className="cover-iframe-title">Birthing Diversity, an introduction.</div>
              <div className="cover-iframe-play" ><FontAwesomeIcon icon={faPlay} /></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default PregnancyCare;