// import React, { useState } from 'react';
import '../../App.css';
import './PregnancyCare.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faRightLong } from '@fortawesome/free-solid-svg-icons';
// import { useOutletContext } from 'react-router';

const GDS = () => {
  // const [language] = useOutletContext();
  // const [iframeActive, setIframeActive] = useState(false);
  // const [ytUrl, setYtUrl] = useState("https://www.youtube.com/embed/KIOelF8DKC8?origin=http://birthingdiversity.ca")

  // const onIntroVidPlay = () => {
  //   setIframeActive(true);
  //   setYtUrl('https://www.youtube.com/embed/KIOelF8DKC8?autoplay=1');
  // }

  return (
    <main className='GDS scroll-container'>
      <section className='GDS'>
          <h1>Gestational Diabetes</h1>
      </section>
      <section className='articles'>
        <div className="articles">
          <article>
            <h2>
              What is Gestational Diabetes?
            </h2>
            <p>
              Gestational Diabetes Mellitus (GDM) is a type of diabetes that you can develop during the second or third trimester of pregnancy between 24-28 weeks. Having a diagnosis of GDM means that you have glucose (sugar) intolerance in your blood and your body is unable to produce sufficient insulin to manage the impacts of a growing baby and variable hormone levels. Generally, after you have the baby, diabetes disappears.
            </p>
            <p>
              The glucose screening test checks for higher-than-normal levels of glucose (aka sugar) in your blood, which could mean that you have or are at risk of developing Gestational Diabetes. Uncontrolled sugar levels might lead to issues for both you and your infant. Risks can be reduced by treating blood sugar levels and modifying your lifestyle such as managing your weight, and diet as well as keeping active.
            </p>
          </article>
        </div>
          <div className="home-vid">
            <div className="vid-wrapper">
              {/* <iframe 
                id='intro-iframe'
                // className={iframeActive ? "active" : "inactive"}
                src={ytUrl} 
                title="Nursing vs Midwifery: Which Career is Right For Me?" 
                frameborder="0"
                allowFullScreen
              ></iframe> */}
              <div className="cover-iframe-color"></div>
              <div className="cover-iframe-img"></div>
              <div className="cover-iframe-top-container" >
                <div className="cover-iframe-title">Gestational Diabetes Animation (comming soon)</div>
                <div className="cover-iframe-play" ><FontAwesomeIcon icon={faPlay} /></div>
              </div>
            </div>
          </div>
      </section>
      <section className="gd-screening">
        <h2>
          Screening for Gestational Diabetes
        </h2>
        <div className='gd-screening-intro'>
          <p>
            Screening for gestational diabetes is a way to look for high blood sugar during pregnancy. You drink some very sweet liquid. Then you have a blood test to see how your body uses sugar (glucose).
          </p>
          <img src='/images/ugly-glucose-drink.png' alt="pregnant parent" />
        </div>
        <section className='gct-gtt'>
          <div className="gct">
            <h3 className='' >
              Glucose Challenge Test (GCT)
            </h3>
            <ul>
              <li>Done between 24-28 weeks</li>
              <li>Eat a normal breakfast</li>
              <li>Go to lab before 11a.m.</li>
              <li>
                Have a special orange glucose drink; encouraged you to finish the drink within 5 minutes
              </li>
              <li>Wait one hour, then do blood test</li>
              <li>If this is normal, no gestational diabetes</li>
              <li>If this is not normal, do GTT</li>
            </ul>
          </div>
          <div className="arrow">
            <FontAwesomeIcon icon={faRightLong} />
          </div>
          <div className="gtt">
            <h3>
              Glucose Tolerance Test (GTT)
            </h3>
            <ul>
              <li>Done if GCT abnormal</li>
              <li>Fast for 8 hours</li>
              <li>
                Do three sets of blood tests
                <li>one before the drink</li>
                <li>one an hour after the drink</li>
                <li>one two hours after the drink</li>
              </li>
              <li>If this is normal, you don't have diabetes, even if the GCT was abnormal</li>
            </ul>
          </div>
        </section>
      </section>
      <section className='additional-resources'>
          <h3>References</h3>
          <ul>
            <li><a href="https://www.ontariomidwives.ca/sites/default/files/Gestational-diabetes-mellitus-backgrounder-PUB_0.pdf">https://www.ontariomidwives.ca/sites/default/files/Gestational-diabetes-mellitus-backgrounder-PUB_0.pdf</a></li>
            <li><a href="https://www.jogc.com/article/S1701-2163(19)30298-1/fulltext">https://www.jogc.com/article/S1701-2163(19)30298-1/fulltext</a></li>
            <li><a href="https://www.canada.ca/en/public-health/services/publications/healthy-living/maternal-diabetes-canada.html">https://www.canada.ca/en/public-health/services/publications/healthy-living/maternal-diabetes-canada.html</a></li>
            <li><a href="https://www.albertahealthservices.ca/assets/wf/lab/wf-lab-patient-instruction-sheet-glucose-tolerance-test.pdf">https://www.albertahealthservices.ca/assets/wf/lab/wf-lab-patient-instruction-sheet-glucose-tolerance-test.pdf</a></li>
            <li><a href="https://www.nygh.on.ca/areas-care/maternal-newborn-and-paediatric-care/pregnancy-and-birth/guide-pregnancy-and-birth/during-pregnancy/gestational-diabetes">https://www.nygh.on.ca/areas-care/maternal-newborn-and-paediatric-care/pregnancy-and-birth/guide-pregnancy-and-birth/during-pregnancy/gestational-diabetes</a></li>
          </ul>
      </section>
    </main>
  )
}

export default GDS;