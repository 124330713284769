import '../../App.css';
import './PregnancyCare.css';


const PS = () => {


  return (
    <main className='scroll-container'>
      <section className='home-pages'>

      </section>
    </main>
  )
}

export default PS;