import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './components/Home';
import Contact from './components/Contact';
import PostpartumCare from './components/PostpartumCare';
import FamilyPlanning from './components/FamilyPlanning';
import PregnancyCare from './components/PregnancyCarePages/PregnancyCare';
import LocationsMap from './components/LocationsMap';
import ErrorPage from "./components/ErrorPage";
import About from './components/About';
import PS from './components/PregnancyCarePages/PS';
import GDS from './components/PregnancyCarePages/GDS';
import GBS from './components/PregnancyCarePages/GBS';
import VBAC from './components/PregnancyCarePages/VBAC';
import Birthplace from './components/PregnancyCarePages/Birthplace';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'postpartum',
        element: <PostpartumCare />
      },
      {
        path: 'family-planning',
        element: <FamilyPlanning />
      },
      {
        path: 'pregnancy-care',
        element: <PregnancyCare />,
        children: [
        ]
      },
      {
        path: 'prenatal-screening',
        element: <PS />
      },
      {
        path: 'GDS',
        element: <GDS />
      },
      {
        path: 'GBS',
        element: <GBS />
      },
      {
        path: 'VBAC',
        element: <VBAC />
      },
      {
        path: 'Birthplace',
        element: <Birthplace />
      },
      {
        path: 'map',
        element: <LocationsMap />
      },
    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
