import '../../App.css';
import './PregnancyCare.css';

const VBAC = () => {


  return (
    <main className='scroll-container'>
      <section className='home-pages'>
 
      </section>
    </main>
  )
}

export default VBAC;