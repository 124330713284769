import { useNavigate } from "react-router-dom";
import LanguageSelection from "./LanguageSelection";
import './Navbar.css';
import library from "../LanguageLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faMapLocationDot, faBars, faHome } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";


const Navbar = (props) => {
  const { language, setLanguage, navContent, landingPage, setLandingPage, languagePage, setLanguagePage, navMenu, setNavMenu } = props;

  const [pca, setpca] = useState(false)

  const navigate = useNavigate();

  const HandleMenuButtonClick = () => {
    navMenu ? setNavMenu(false) : setNavMenu(true);
    handleLandingPageLink();
  }
  const handleLandingPageLink = ()=> {
    landingPage ? setLandingPage(false) : setLandingPage(true)
    setNavMenu(false)
  }

  const handleScreenWidth = () => {
    const ww = window.innerWidth
    if (ww < 940) {
      setNavMenu(false)
    }
  }
  window.addEventListener('resize', handleScreenWidth)

  return (
    <header className="nav">
        <nav>
          <img className="nav-logo" src='/images/BD-logo3.0.png' alt='birthing diversity logo'/>
          <div className={navMenu ? 'menu-active' : 'menu-not-active'} >
            <ul>
              {navContent !== 'pregnancy' ?
                <>
                  <div className='left-nav'>
                    <li onClick={() => {navigate('/'); HandleMenuButtonClick(''); }} >{library[language].navbar.home}</li>
                  </div>
                  <div className='right-nav'>
                    <li onClick={() => {navigate('/about'); HandleMenuButtonClick(''); }} ><FontAwesomeIcon icon={faMapLocationDot} />&nbsp;{library[language].navbar.map}</li>
                    <li onClick={() => {navigate('/map'); HandleMenuButtonClick(''); }} ><FontAwesomeIcon icon={faPeopleGroup} />&nbsp;{library[language].navbar.about}</li>
                  </div>
                </>
                :
                <>
                  <div className='left-nav'>
                    <li onClick={() => {navigate('/'); HandleMenuButtonClick(''); }} >{library[language].navbar.home}</li>
                    {pca ? <li onClick={() => {navigate('/pregnancy-care'); setpca(false)}}><FontAwesomeIcon icon={faHome} />&nbsp;Pregnancy</li> : <div></div>}
                    <li  >{library[language].navbar.trimesterOne}
                      <ul>
                        <li  >Prenatal Screening</li>
                      </ul>
                    </li>
                    <li  >{library[language].navbar.trimesterTwo}</li>
                    <li  >{library[language].navbar.trimesterThree}
                      <ul>
                        <li onClick={() => {navigate('/GDS'); setpca(true)}} >Gestational Diabetes Screening</li>
                        <li  >Group B Streptococcus (GBS) Screening</li>
                        <li  >Vaginal Birth After Cesarean (VBAC)</li>
                        <li  >Birthplace</li>
                      </ul>
                    </li>
                  </div>
                  <div className='right-nav'>
                    <li onClick={() => {navigate('/map'); }}><FontAwesomeIcon icon={faMapLocationDot} />&nbsp;{library[language].navbar.map}</li>
                    <li onClick={() => {navigate('/about'); }}><FontAwesomeIcon icon={faPeopleGroup} />&nbsp;{library[language].navbar.about}</li>
                  </div>
                </>
              }
            </ul>
          </div>
          <ul className="menu-button" >
            <li onClick={() => HandleMenuButtonClick()}><FontAwesomeIcon icon={faBars} />&nbsp;{library[language].navbar.menu}</li>
          </ul>
        </nav>
        {languagePage ?
          <LanguageSelection setLanguagePage={setLanguagePage} setLanguage={setLanguage} />
        :
          <div></div>
        }
      </header>
  )
}

export default Navbar;