import '../../App.css';
import './PregnancyCare.css';

const Birthplace = () => {


  return (
    <main className='scroll-container'>
      <section className='home-pages'>

      </section>
    </main>
  )
}

export default Birthplace;